/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Mark Brudenell';
  const content =
    'Chief Risk Officer and Executive General Manager, Risk.<br />Acting EGM Enterprise Services';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={ReactHTMLParser(content)}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Mark Brudenell'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/mark-brudenell.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Mark joined Latitude Financial in 2018 as Chief of Staff. He has
            since held a variety of roles in risk management including General
            Manager Group Risk, Chief Risk Officer (reporting to the EGM
            Finance) and Executive General Manager Risk & CRO, which he was
            appointed to in May 2023. As EGM Risk, Mark is accountable for
            ensuring that all risk types are effectively managed by Latitude and
            its people through robust governance and risk management practices.
            <br />
            <br />
            Prior to Latitude, Mark had an extensive career in public policy
            where he was responsible for developing and executing major policy
            and reform initiatives as Senior Adviser to the Prime Minister of
            Australia. He previously worked as an Adviser to the Minister for
            Communications, where he was responsible for establishing
            Australia's Digital Transformation Agency (DTA) and overseeing the
            merger between National ICT Australia and CSIRO's Digital
            Productivity Unit to form Data61, and as Senior Adviser to the
            Minister for Science, Information Technology, Innovation and the
            Arts.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Mark began his career at Deloitte and currently serves on the Board
            of the Australian Retail Credit Association (ARCA), Australia's peak
            body for consumer credit.
            <br />
            <br />
            Outside of Latitude, Mark maintains a keen interest in public
            affairs and is a passionate supporter of the North Melbourne
            Kangaroos.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
